import React from "react";

import {
  ProjectListContainer,
  ProjectListGrid,
} from "../styled/Project.styled";
import ProjectCard from "./ProjectCardSimple";

const ProjectList = ({ projects, category }) => {
  return (
    <ProjectListContainer>
      <ProjectListGrid>
        {projects.map(project => (
          <ProjectCard
            project={project}
            category={category}
            key={project.title}
          />
        ))}
      </ProjectListGrid>
    </ProjectListContainer>
  );
};

export default ProjectList;
