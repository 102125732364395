import React, { useContext } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import ProjectList from "../components/ProjectList";
import { PageContainer, PageArticle } from "../styled/Layout.styled";
import { markdownByLang, extractMarkdownProjects } from "../utils/markdown";
import { LangContext } from "../context/LangContext";

const CategoryPage = ({ data, pageContext }) => {
  const { lang } = useContext(LangContext);
  const content = markdownByLang(data);
  const projects = extractMarkdownProjects(data);

  return (
    <Layout>
      <PageContainer>
        <h1>{content[lang].heading}</h1>
        <h2>{content[lang].description}</h2>
        <PageArticle
          dangerouslySetInnerHTML={{ __html: content[lang].html }}
        ></PageArticle>
        <ProjectList projects={projects} category={pageContext.category} />
      </PageContainer>
    </Layout>
  );
};

export default CategoryPage;

export const query = graphql`
  query CategoryPageContent(
    $category: String
    $categoryPage: String
    $thumbnails: [String]
  ) {
    page: allMarkdownRemark(
      filter: { frontmatter: { title: { regex: $categoryPage } } }
    ) {
      nodes {
        html
        frontmatter {
          lang
          heading
          description
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: frontmatter___sortOrder }
    ) {
      nodes {
        frontmatter {
          title
          heading
          description
          lang
          thumbPosition
          pictures {
            image
          }
        }
      }
    }
    pics: allFile(filter: { relativePath: { in: $thumbnails } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        }
        relativePath
      }
    }
  }
`;
