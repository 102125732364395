import React, { useContext } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { LangContext } from "../context/LangContext";

const ProjectListItem = styled.li`
  border-radius: 2px;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  transition: 1s;
  width: 100%;
  height: 200px;
  position: relative;
  & > h4 {
    position: absolute;
    font-family: "Barriecito";
    font-size: 42px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    color: white;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5), 0px 0px 2px rgba(0, 0, 0, 0.15),
      0px 0px 12px rgba(0, 0, 0, 0.85);
  }
  & > div {
    width: 100%;
    height: 100%;
    img {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      object-position: ${({ thumbPosition }) =>
        thumbPosition ? thumbPosition : "center"};
      filter: grayscale(1);
    }
  }
  &:hover {
    & > div {
      img {
        transition: 2000ms;
        filter: none;
      }
    }
  }
`;

const ProjectCard = ({ project, category, showDescription = false }) => {
  const { lang } = useContext(LangContext);

  if (!project[lang])
    return (
      <ProjectListItem
        onClick={() => navigate(`/${category}/${project.title}`)}
      >
        {project.title}
      </ProjectListItem>
    );

  return (
    <ProjectListItem
      onClick={() => navigate(`/${category}/${project.title}`)}
      thumbPosition={project.thumbPosition}
    >
      <GatsbyImage
        image={getImage(project.thumbnail)}
        alt={project.title}
        objectFit="cover"
      />
      <h4>{project[lang].heading}</h4>
    </ProjectListItem>
  );
};

export default ProjectCard;
